.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #000;
  }
  
  .logo img {
    width: 200px;
    align-self: center;
    margin-bottom: 20px;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  
  .login-form {
    
  }
  
  .form-group {
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color:#fff;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
  }
  
  .login-button {
    width: 60%;
    align-self: center;
    padding: 10px;
    margin-top: 30px;
    background-color: #007bff;
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  